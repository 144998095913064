/**
 * Created by andrey on 09.02.2021.
 */

var Map2dDecoratorView = cc.Node.extend({
    ctor: function (decorator) {
        this._super();

        this.decorator = decorator;

        this.updateDecorator();
        this.changeSkin(this.decorator.getSkin(), true);
        this.changeAnimation(this.decorator.getAnimation(), true);

        this.decorator.isOnScreen = this.createListener(this.isOnScreen.bind(this));
        this.decorator.onSmile = this.createListener(this.smile.bind(this));
        this.decorator.getView = this.createListener(function () {
            return this;
        }.bind(this));

        this.decorator.onAnimateHover = this.createListener(this.animateHover.bind(this));
        this.decorator.onStopAnimateHover = this.createListener(this.stopAnimateHover.bind(this));
        this.decorator.onSetSkin = this.createListener(this.changeSkin.bind(this));
        this.decorator.onSetAnimation = this.createListener(this.changeAnimation.bind(this));
    },

    updateDecorator: function () {
        var decorator = this.decorator;

        var image = this.image = Map2dDecoratorView.getDecoratorImage(decorator, this.decorator.animation);
        this.addChild(image);

        if (cleverapps.config.adminMode) {
            if (image instanceof cleverapps.Spine && image.hasAnimation("smile")) {
                image.setAnimation(0, "smile", true);
            }
        }

        this.setContentSize2(image);
        image.setPosition(image.width / 2, image.height / 2);

        var dx = (decorator.dx || 0) * resolutionScale;
        var dy = (decorator.dy || 0) * resolutionScale;

        var useAnchorBone = image.getAnchorBone && image.getAnchorBone();

        if (useAnchorBone) {
            this.setAnchorPoint(image.getAnchorPoint());
            image.setAnchorPoint(0.5, 0.5);
        } else {
            this.setAnchorPoint(0.5, 0.5);
        }

        if (dx || dy) {
            this.setPositionRound({ align: "center", dx: dx }, { align: "center", dy: dy });
        }

        this.setRotation(decorator.rotation || 0);
        this.setScale(decorator.scaleX || 1, decorator.scaleY || 1);
        this.setLocalZOrder(decorator.getLocalZOrder());
    },

    isOnScreen: function () {
        return this.image.isVisible() && cleverapps.UI.isNodeOnScene(this.image, 2, 2);
    },

    smile: function () {
        this.image.setCompleteListener(function () {
            this.image.setCompleteListener();
            this.image.setAnimationAndIdleAfter("smile", "animation");

            if (!Map2dDecoratorView.smileSoundTimeouts[this.decorator.code] || Date.now() > Map2dDecoratorView.smileSoundTimeouts[this.decorator.code] + Map2dDecoratorView.SMILE_SOUND_THROTTLE) {
                Map2dDecoratorView.smileSoundTimeouts[this.decorator.code] = Date.now();
                cleverapps.audio.playSound(Map2dDecoratorView.getEffect(this.decorator), {
                    throttle: Map2dDecoratorView.SMILE_SOUND_THROTTLE
                });
            }
        }.bind(this));
    },

    animateHover: function () {
        if (this.image instanceof cleverapps.Spine) {
            var blink = StandartAnimations.blink(this.image.spine, {
                brightness: 0.3
            });
            this.hoverAnimation = this.image.spine.runAction(
                new cc.RepeatForever(blink)
            );
            this.hoverAnimation.setFinalize(function () {
                blink.stop();
            });
        }
    },

    stopAnimateHover: function () {
        if (this.hoverAnimation) {
            this.image.spine.stopAction(this.hoverAnimation);
            delete this.hoverAnimation;
        }
    },

    changeSkin: function (skin, silent) {
        if (!(this.image instanceof cleverapps.Spine) || !this.image.hasSkin(skin)) {
            return;
        }
        this.image.setSkin(skin);

        if (silent || !this.image.hasAnimation("change")) {
            this.image.setAnimation(0, "idle");
        } else {
            this.image.setAnimationAndIdleAfter("change", "idle");
        }
    },

    changeAnimation: function (animation, silent, callback) {
        callback = callback || function () {};
        if (!(this.image instanceof cleverapps.Spine) || !this.image.hasAnimation(animation)) {
            return;
        }

        if (silent || !this.image.hasAnimation("change")) {
            this.image.setAnimation(0, animation);
            callback();
        } else {
            this.image.setAnimationAndIdleAfter("change", animation);
            this.image.setCompleteListenerOnce(callback);
        }
    },

    checkTouchInside: function (touch) {
        return cleverapps.UI.targetContainPoint(this, this.convertTouchToNodeSpace(touch));
    }
});

Map2dDecoratorView.processDecoratorUrl = function (decorator) {
    var index = decorator.code && decorator.code.indexOf("#");
    if (index > -1) {
        return {
            bundle: decorator.code.slice(0, index),
            url: decorator.code.slice(index + 1)
        };
    }

    return {
        bundle: "decorator_" + decorator.code,
        url: "decorator_" + decorator.code
    };
};

Map2dDecoratorView.getFrame = function (decorator) {
    var url = Map2dDecoratorView.processDecoratorUrl(decorator);
    return bundles[url.bundle] && bundles[url.bundle].frames[url.url];
};

Map2dDecoratorView.getJson = function (decorator) {
    var url = Map2dDecoratorView.processDecoratorUrl(decorator);
    return bundles[url.bundle] && bundles[url.bundle].jsons[url.url];
};

Map2dDecoratorView.getEffect = function (decorator) {
    var url = Map2dDecoratorView.processDecoratorUrl(decorator);
    return bundles[url.bundle] && bundles[url.bundle].urls[url.url + "_effect"];
};

Map2dDecoratorView.DecoratorImageExists = function (decorator) {
    return Map2dDecoratorView.getJson(decorator) || Map2dDecoratorView.getFrame(decorator);
};

Map2dDecoratorView.getDecoratorImage = function (decorator) {
    var image = Map2dDecoratorView.getJson(decorator);

    if (image) {
        var animation = new cleverapps.Spine(image);
        animation.setAnimation(0, animation.hasAnimation("animation") ? "animation" : "idle", true);

        return animation;
    }

    image = Map2dDecoratorView.getFrame(decorator);

    if (image) {
        return new cc.Sprite(image);
    }

    throw "Not found resources for decorator " + decorator.code;
};

Map2dDecoratorView.smileSoundTimeouts = {};
Map2dDecoratorView.SMILE_SOUND_THROTTLE = cleverapps.parseInterval("5 seconds");
